<template>
  <div
    v-cloak
    id="order_scan"
    class="col-md-12"
    style="padding: 30px"
  >
    <div class="header_scan">
      <div class="col-lg-12">
        <div class="">
          <u id="back">
            <router-link
              class="mb-5"
              :to="{name: 'pages-order-scan-billing'}"
            ><h5>
              <i
                class="fa fa-arrow-left"
                aria-hidden="true"
              ></i> ย้อนกลับ
            </h5>
            </router-link>
          </u>
          <div
            style="margin-top:10px;padding: 0.75rem 1.25rem;margin-bottom: 0;background-color: rgba(0, 0, 0, .03);border-bottom: 1px solid rgba(0, 0, 0, .125);"
            class="card-header"
          >
            ระบบสแกนบิล <i
              class="fa fa-file-o"
              aria-hidden="true"
            ></i>
          </div>
          <div class="">
            <div id="report_booking_order">
              <div
                id="main"
                class="col-md-12"
              >
                <br>
                <div class="store-filter clearfix">
                  <center>
                    <h1>สรุปบิลทั้งหมด</h1>
                  </center>
                </div>
                <br>
              </div>
            </div>
            <div class="col-md-2 float-right print">
              <v-btn
                class="success-badge mr-5"
                :disabled="(!order_details || order_details.length ==0) || (!order_inv_array || order_inv_array.length ==0)"
                onclick="window.print();return false;"
              >
                พิมพ์รายการ
              </v-btn>
              <v-btn
                class="warning-badge"
                :disabled="(!order_details || order_details.length ==0) || (!order_inv_array || order_inv_array.length ==0)"
                @click="save"
              >
                สร้างใบเบิก
              </v-btn>
            </div>
            <div class="">
              <div
                id="search"
                class="form-group col-md-4"
              >
                <v-text-field
                  id="order_inv"
                  v-model="order_inv"
                  label="ค้นหาเลขที่บิล"
                  placeholder="เลขบิล"
                  @blur="search"
                  @keyup.enter="$event.target.blur()"
                ></v-text-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class=""
      style="background-color: white;"
    >
      <div class="row">
        <div class="col-md-12">
          <table style="width: 100%;height: 100%">
            <thead>
              <tr style="background-color: black;color: white;font-size: 18px">
                <th
                  scope="col"
                  style="text-align: center;width: 10%"
                >
                  ลำดับ
                </th>
                <th
                  scope="col"
                  colspan="2"
                  style="text-align: center;width: 40%"
                >
                  รายการ
                </th>
                <th
                  scope="col"
                  style="text-align: center;width: 5%"
                >
                  ราคาหน่วย
                </th>
                <th
                  scope="col"
                  style="text-align: center;width: 5%"
                >
                  จำนวน
                </th>
                <th
                  scope="col"
                  style="text-align: right;width: 5%"
                >
                  รวม
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(list,index) in order_details"
                :key="index"
              >
                <td style="text-align: center">
                  {{ index + 1 }}
                </td>

                <td colspan="2">
                  <div v-if="list.product_id">
                    <br> <b style="color:black;">
                      {{ list.product_name }} </b><br>
                  </div>
                </td>
                <td style="text-align: center">
                  {{ list.product_costs }}
                </td>
                <td style="text-align: center">
                  {{ list.product_amount }}
                </td>

                <td style="width: 10%;text-align: right">
                  <b><span
                    v-if="list.product_id"
                  > {{ parseFloat(( list.product_costs * list.product_amount)).toFixed(2) }}</span></b>
                </td>
              </tr>
              <tr>
                <td
                  colspan="5"
                  style="text-align: right"
                >
                  <b><h3>จำนวนชิ้นทั้งหมด</h3>
                  </b>
                </td>
                <td style="text-align: right">
                  <b><h3>{{ amount }} ชิ้น</h3></b>
                </td>
              </tr>
              <tr>
                <td
                  colspan="5"
                  style="text-align: right"
                >
                  <b><h3>ยอมรวมทั้งหมด</h3></b>
                </td>
                <td style="text-align: right">
                  <b><h3>{{ total }} บาท </h3></b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-12">
          <table style="width:auto">
            <thead style="background-color: black;">
              <tr style="color: white;font-size: 18px;collapse: 0">
                <th colspan="2">
                  # เลขที่บิล
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(order,index) in order_inv_array">
                <td> {{ index + 1 }}</td>
                <td> {{ order.order_inv }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'

// import config from '../../../config'
import Swal from 'sweetalert2'
import instance from '../../../axios.service'
import instance_report from '../../../services/report'
import instance_order from '../../../services/order'
import instance_stock_employee from '../../../services/stockEmokoyee'

export default {
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    order_details: [],
    orders: [],
    orders_select: {},
    order_inv: '',
    order_inv_array: [],
    type: 'ใบสั่งซื้อสินค้า Purchase Order',
    purchase_order_type: 1,
    supplier_name: '',
    employee_name: '',
    employee_id: '',
    purchase_on: '',
    tax_id: '',
    address: '',
    date: '',
    payment_id: 'เลือกการชำระเงิน',
    contact_name: '',
    project_name: '',
    sub_total: 0,
    discount: 0,
    vat: 0,
    note: '',
    is_vat: true,
  }),

  computed: {

    amount() {
      const total = []
      Object.entries(this.order_details).forEach(([key, val]) => {
        if (val.product_amount) {
          total.push(parseInt(val.product_amount))
        } else {
          total.push(0)
        }
      })

      return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
    },
    total() {
      const total = []
      Object.entries(this.order_details).forEach(([key, val]) => {
        if (val.product_amount) {
          total.push(parseInt(val.product_amount) * parseInt(val.product_costs))
        } else {
          total.push(0)
        }
      })

      return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
    },
  },

  watch: {
    serverOptions: {
      handler() {
        this.getReport()
      },
    },
    search: {
      handler() {
        this.getReport()
      },
    },

    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    document.getElementById('order_inv').focus()

    // this.getUser()
    // this.getReport()
  },

  methods: {
    print() {
      window.print()
    },
    Add() {
      this.getOrderDetailsByID()

      // this.order_details.push(this.orders_select)
    },
    save() {
      // let data = new FormData()
      this.$store.state.isLoading = true
      Object.entries(this.order_details).forEach(([key, val]) => {
        this.order_details[key].amount = val.product_amount
      })

      const data = {
        data: this.order_details,
        order_inv_array: this.order_inv_array,

        // 'employee_id' : "{{auth()->user()->member_id}})",
      }

      // data.append('data', JSON.stringify($this.order_details));
      // data.append('order_inv_array', JSON.stringify($this.order_inv_array));
      // data.append('employee_id', {{auth()->user()->member_id}});

      instance_stock_employee.store(data).then(res => {
        this.status = 'รอทำการเบิกสินค้า'
        this.$store.state.isLoading = false
        Swal.fire({
          text: 'สร้างรายการเบิกสินค้าสำเสร็จ',
          timer: 3000,
          icon: 'success',
          showConfirmButton: false,
          button: false,
        })
        this.$router.push({ name: 'pages-stock-employee' })
      }).catch(err => {
        this.$store.state.isLoading = false
        Swal.fire({
          text: err.message,
          timer: 3000,
          icon: 'error',
          showConfirmButton: false,
          button: false,
        })
      })
    },
    search() {
      let check = true
      if (this.order_inv) {
        Object.entries(this.order_inv_array).forEach(([key, val]) => {
          if (val.order_inv === this.order_inv) {
            document.getElementById('order_inv').focus()
            this.order_inv = ''
            check = false
            Swal.fire({
              text: `เลขที่บิล ${val.order_inv} ซ้ำมีในรายการอยู่เเล้ว`,
              icon: 'error',
              showConfirmButton: false,
              button: false,
            })
          }
        })
      }

      if (check) {
        if (this.order_inv) {
          this.getOrder()
        }
      }
    },
    getOrder() {
      this.$store.state.isLoading = true
      instance_order.getOrderInv(this.order_inv).then(res => {
        if (res && res.data) {
          this.orders_select = res.data.data

          this.getOrderDetailsByID(this.orders_select.order_id)
        }
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
    getOrderDetailsByID() {
      instance_order.getOrderById(this.orders_select.order_id).then(res => {
        if (res && res.data.length > 0) {
          console.log(res.data, 5544)
          Object.entries(res.data).forEach(([key2, val2]) => {
            console.log(val2, 111)

            this.order_details.push(val2)
          })

          // console.log(this.order_details, 7777)

          Swal.fire({
            text: `เพิ่อบิลเรียบร้อย ${res.data.length} รายการ`,
            timer: 2000,
            icon: 'success',
            showConfirmButton: false,
            button: false,
          })
          document.getElementById('order_inv').focus()

          this.order_inv_array.push({
            order_inv: this.orders_select.order_inv,
            order_id: this.orders_select.order_id,
          })

          const result = []

          this.order_details.reduce((res, value) => {
            console.log(this.order_details, 11)

            // console.log(value, 22)
            if (!res[parseInt(value.product_id)]) {
              res[value.product_id] = {
                product_id: value.product_id,
                product_amount: 0,
                product_name: value.product ? value.product.product_name : value.product_name,
                product_costs: value.product ? value.product.product_costs : value.product_costs,
              }
              result.push(res[value.product_id])
            }
            res[value.product_id].product_amount += parseInt(value.product_amount)

            return res
          }, {})
          console.log(result, 5555)
          this.$store.state.isLoading = false
          this.order_details = result
          this.orders_select = {}
          this.order_inv = ''
        } else {
          this.$store.state.isLoading = false
          Swal.fire({
            text: 'ไม่พบเลขที่บิล/เปิดนี้สแกนเเล้ว',
            timer: 3000,
            icon: 'error',
            showConfirmButton: false,
            button: false,
          })
        }
      }).catch(err => {
        this.$store.state.isLoading = false
        Swal.fire({
          text: err.message,
          timer: 3000,
          icon: 'error',
          showConfirmButton: false,
          button: false,
        })
      })
    },
  },
}
</script>

<style scoped>
body {
  background-color: white;
}

@media print {
  .card-header {
    display: none;
  }

  .print {
    display: none;
  }

  #order_inv {
    display: none;
  }

  #search {
    display: none;
  }

  #back {
    display: none;
  }

  #header_scan {
    display: none;
  }

}

</style>
